import React from 'react'
import Img from 'gatsby-image'
import OffCanvas, { OffCanvasHeader, OffCanvasBody } from '../../../components/shared/off-canvas'
import Logo from '../../../components/logo'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import Social from '../../../components/socials/layout-two'
import { AuthorImg, SocialWrap } from './author-offcanvas.stc'

const AuthorOffcanvas = ({isOpen, onClick, authorData, ...restProps }) => {
    
    const { name, tagline, image } = authorData
    const bio = authorData.shortBio.shortBio
    const {twitter, facebook, instagram, linkedin, youtube, github} = authorData
    const social = {
        twitter: twitter,
        facebook: facebook,
        instagram: instagram,
        linkedin: linkedin,
        youtube: youtube,
        github: github,
    }

    const {offCanvasCSS, bioCSS, offCanvasBodyCSS} = restProps
    return (
        <OffCanvas isOpen={isOpen} onClick={onClick} {...offCanvasCSS}>
            <OffCanvasHeader onClick={onClick}>
                <Logo />
            </OffCanvasHeader>
            <OffCanvasBody {...offCanvasBodyCSS}>
                {image && (
                    <AuthorImg>
                        <Img fixed={image.fixed} alt={name}/>
                    </AuthorImg>
                )}
                {name && <Heading as="h3">{name}</Heading>}
                {tagline && <Heading as="h5">{tagline}</Heading>}
                {bio && <Text {...bioCSS}>{bio}</Text>}
                {social && (
                    <SocialWrap>
                        <Social layout={2} social={social}/>
                    </SocialWrap>
                )}
            </OffCanvasBody>
        </OffCanvas>
    )
}

AuthorOffcanvas.defaultProps = {
    authorData: {
        
    },
    offCanvasCSS: {
        align: 'left'
    },
    offCanvasBodyCSS: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    bioCSS: {
        mb: '15px',
        mt: '15px'
    }
}

export default AuthorOffcanvas
